.sidebar-widget{
    margin-bottom: 1rem;
    background: $sidebar-widget-bg;
    padding: 1rem 0.5rem;
    box-shadow: 2px 7px 14px hsla(0,0%,71%,.7);
    .sidebar-widget__title{
        font-size: 2rem;
        font-weight: bold;
    }
}

.sidebar-menu{
    list-style: none;
    padding: 0;
    .sidebar-menu__item{
        padding: 0.5em 0.5em;
        position: relative;
        &:not(:last-child){
            border-bottom: 1px solid #eee;
        }
        &:before{
            content: '';
            position: absolute;
            width: 2px;
            top: 4px;
            bottom: 4px;
            left: 0;
            background: rgba($brand-color-1, 0.3);
            border-radius: 4px;
        }
    }
    .sidebar-menu__link{
        color: $brand-color-2;
        // padding: 0.3em 0.5em;
        
    }
}

.banner{
    position: relative;
    display: block;

    .banner-content{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 8px;
        padding: 0.5em 1em;
        border-radius: 4px;
        color: white;
        text-align: center;
        font-weight: bolder;
        background: linear-gradient(135deg,rgba(0,191,255,.8),rgba(35,155,200,.8));


    }
}