.main-slider{
    box-shadow: 2px 7px 14px hsla(0,0%,71%,.7);
    .slide{
        position: relative;
        .slide__image{
            width: 100%;
        }
        .slide__content,
        .slide__overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .slide__content{
            padding: 3rem ;
            display: flex;
            align-items: center;
            color: white;
        }
        .slide__overlay{
            background: linear-gradient(to bottom, rgba(0,0,0,0.3), transparent);
        }
        .slide__text{
            font-size: 1.5rem;
            line-height: 1;
            // background: linear-gradient(135deg,rgba($brand-color-1, 0.5), rgba($brand-color-2, 0.5));
            background: rgba(255, 255, 255, 0.25);;
            backdrop-filter: blur(10px);
            padding: 0.5em 1em;
            border-radius: 50px 5px 50px 5px;
            @include bp-up('sm'){
                font-size: 2rem;
                line-height: 1.2;
            }
            @include bp-up('lg'){
                font-size: 2.5rem;
            }
            @include bp-up('xl'){
                font-size: 3.2rem;
            }
            @include bp-up('xxl'){
                font-size: 4rem;
            }
        }
        
    }
}