.header{
    a,
    a:hover{
        text-decoration: none;
    }
    a{
        color: $brand-color-2;
    }
}
.header-widget{
    display: flex;
    align-items: center;
    

    &__icon{
        font-size: 2em;
        border: 1px solid $brand-color-1;
        color: $brand-color-1;
        border-radius: 25px 5px 25px 5px;
        min-width: 1.5em;
        text-align: center;
    }
    &__content{
        display: flex;
        flex-direction: column;
        margin-left: 0.5em;
    }

    &__phone{
        font-size: 1.5rem;
        color: $brand-color-1;
        line-height: 1;
    }
}

.cart-btn{
    display: flex;
    align-items: center;
    position: relative;

    .cart-btn__icon{
        font-size: 2em;
    }

    .cart-btn__counter{
        background: #eee;
        padding: 0em 0.5em;
        border-radius: 1em;
        min-width: 1em;
        height: 1.5em;
        line-height: 1.5em;
    }
    .cart-btn__text{
        display: none;
        margin: 0 0.5em;
        @include bp-up('md'){
            display: block;
        }
    }
}

.navbar-expand-lg.navbar--wide .navbar-nav{
    .nav-link{
        padding-left: 1rem;
        padding-right: 1rem;

        font-weight: bold;
        @include bp-up('xl'){
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}