@include bp-up('xxl'){
    .d-xxl-none{
        display: none!important;
    }
    .d-xxl-inline{
        display: inline!important;
    }
    .d-xxl-block{
        display: block!important;
    }
    .d-xxl-inline-block{
        display: inline-block!important;
    }
    .d-xxl-flex{
        display: flex!important;
    }

    .d-xxl-inline-flex{
        display: inline-flex!important;
    }
}

@include bp-up('xxl'){
    .row-cols-xxl-1 > *{
        flex: 0 0 calc(100% / 1);
        max-width: calc(100% / 1);
    }

    .row-cols-xxl-2 > *{
        flex: 0 0 calc(100% / 2);
        max-width: calc(100% / 2);
    }

    .row-cols-xxl-3 > *{
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
    }

    .row-cols-xxl-4 > *{
        flex: 0 0 calc(100% / 4);
        max-width: calc(100% / 4);
    }

    .row-cols-xxl-5 > *{
        flex: 0 0 calc(100% / 5);
        max-width: calc(100% / 5);
    }

    .row-cols-xxl-6 > *{
        flex: 0 0 calc(100% / 6);
        max-width: calc(100% / 6);
    }
    
}

body{
    color: $text-color;
}
a,
.page-link{
    color: $brand-color-2;
}

.bg-brand-gradient{
    background: linear-gradient(135deg, rgba($brand-color-1, 0.7), rgba($brand-color-2, 0.7));
}

.container{
    @include bp-up('xxl'){
        max-width: 1400px - 30px;
    }
}
.btn-outline-brand-color-1{
    color: $brand-color-1;
    border-color: $brand-color-1;
    &:hover{
        background: $brand-color-1;
        border-color: $brand-color-1;
        color: white;
    }
}

.btn-outline-brand-color-2{
    color: $brand-color-2;
    border-color: $brand-color-2;
    &:hover{
        background: $brand-color-2;
        border-color: $brand-color-2;
        color: white;
    }
}

.section-title{
    color: $text-color;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5em;
}

.flex-1{
    flex: 1!important;
}

.btn-brand-1 {
    color: #fff;
    background-color: $brand-color-1;
    border-color: $brand-color-1;
}
.btn-brand-1:hover {
    color: #fff;
    background-color: darken($brand-color-1, 7);
    border-color: darken($brand-color-1, 7);
}
.btn-brand-2 {
    color: #fff;
    background-color: $brand-color-2;
    border-color: $brand-color-2;
}

.btn-brand-2:hover {
    color: #fff;
    background-color: darken($brand-color-2, 7);
    border-color: darken($brand-color-2, 7);
}

.btn-outline-brand-1 {
    color: $brand-color-1;
    border-color: $brand-color-1;
}
.btn-outline-brand-1:hover {
    color: #fff;
    background-color: $brand-color-1;
    border-color: $brand-color-1;
}

.btn-outline-brand-2 {
    color: $brand-color-2;
    border-color: $brand-color-2;
}
.btn-outline-brand-2:hover {
    color: #fff;
    background-color: $brand-color-2;
    border-color: $brand-color-2;
}

.bi {
    display: inline-block;
    vertical-align: text-bottom;
    margin-bottom: 0.15em;

    &.bi--90{
        transform: rotateZ(90deg);
    }
    &.bi--180{
        transform: rotateZ(180deg);
    }
    &.bi--270{
        transform: rotateZ(270deg);
    }
}