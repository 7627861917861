.footer-widget{
    .footer-widget__title{
        font-weight: bolder;
        font-size: 1.5rem;
        margin-bottom: 1rem;
        position: relative;
        &:after{
            content: '';
            position: absolute;
            bottom: -0.5em;
            left: 0;
            width: 50px;
            max-width: 100%;
            border-bottom: 2px solid #d4d4d4;
        }
    }
    .footer-widget__list{
        padding-left: 0;
        list-style: none;

        li{
            margin: 0.5em 0;
        }
    }
    a{
        color: $footer-text-color;
    }
    &.footer-widget--2x{
        .footer-widget__list{
            columns: 200px 2;
            li{
                break-inside: avoid;
            }
        }
    }
}

.socials-list{
    padding-left: 0;
    list-style: none;
    li{
        display: inline;
        margin-right: 0.25rem;
        font-size: 1.5rem;
        a,
        a:hover{
            text-decoration: none;
        }
    }
}
.footer-bottom a{
    color: white;
}