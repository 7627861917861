$brand-color-1: #08b8e8;
$brand-color-1: #00bfff;
$brand-color-2: #239bc8;

// $brand-color-1: #00d0ff;
// $brand-color-2: #064984;

$sidebar-widget-bg: #f8f9fa;

$text-color: #5a5a5a;
$footer-text-color: #5a5a5a;

$breakpoints: (
    'xs': 480px,
    'sm': 576px,
    'md': 768px,
    'lg': 992px,
    'xl': 1200px,
    'xxl': 1400px,
) !default;

