.features-item{
    display: flex;
    
    align-items: center;
    
    @include bp-up('md'){
        flex-direction: column;
        text-align: center;
    }
    @include bp-up('lg'){
        flex-direction: row;
        text-align: left;

    }

    .features-item__icon{
        font-size: 4rem;
        color: $brand-color-2;
        margin-right: 1rem;
        @include bp-up('md'){
            margin-right: 0rem;
        }
        @include bp-up('lg'){
            margin-right: 1rem;
        }
    }
    .features-item__title{
        color: $brand-color-2;
        color: $text-color;
        font-size: 1.2rem;
    }
}