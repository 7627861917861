.last-news-item{
    display: flex;
    margin-bottom: 1rem;;

    .last-news-item__img{
        flex: 0 0 100px;
        margin-right: 1rem;
    }
    .last-news-item__title{
        color: $brand-color-2;
        line-height: 1;

    }
    .last-news-item__date{
        color: $text-color;
    }
    .last-news-item__content{

    }

}