.catalogue-item{
    position: relative;
    height: 0;
    padding: 0 0 3/4*100% 0;
    margin-bottom: 1rem;
    box-shadow: 2px 7px 14px rgba(180, 180, 180, 0.7);
    overflow: hidden;
    display: block;
    transition: box-shadow 0.3s;
    

    .catalogue-item__img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s, filter 0.3s;
        transform: scale(1);
        filter: saturate(1);
    }

    .catalogue-item__title{
        position: absolute;
        left: 0;
        bottom: 0.5rem;
        max-width: 80%;
        height: auto;
        padding: 0.5em 1em;
        background-color: rgba(0,0,0,0.3);
        color: white;
        background: linear-gradient(135deg, rgba($brand-color-1, 0.8), rgba($brand-color-2, 0.8));
        border-radius: 0 4px 4px 0;
        font-weight: bolder;
    }

    &:hover{
        box-shadow: 2px 7px 20px rgba(180, 180, 180, 0.8);
        .catalogue-item__img{
            transform: scale(1.1);
            filter: saturate(1.5);
        }
    }
}

.qty-field{
    position: relative;
    border-radius: .25rem;
    .qty-input{
        width: 100%;
        height: 100%;
        padding-right: 2.5em;
    }

    .qty-plus,
    .qty-minus{
        position: absolute;
        right: 0;
        height: 50%;
        width: 2em;
        background: #fff;
        border: 1px solid #ced4da;
        line-height: 1;
        font-size: 0.8em;
    }
    .qty-plus{
        top: 0;
        border-radius: 0 .25rem 0 0;
    }
    .qty-minus{
        bottom: 0;
        border-radius: 0 0 .25rem 0;
        border-top: none;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.product{
    .product__price,
    .product__old-price{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .product__price{
        color: $brand-color-2;
    }

    .product__old-price{
        font-weight: normal;
    }
}