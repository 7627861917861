.testimonials-slider{

}

.testimonial{
    display: flex;
    flex-direction: column;
    @include bp-up('sm'){
        padding: 2rem 3rem 2rem 0;    
        flex-direction: row;
    }
    .testimonial__image{
        flex: 0 0 auto;
        display: flex;
        margin-right: 1rem;
        justify-content: center;
        align-items: flex-start;
    }
    .testimonial__content{
        flex: 1;
    }
    .testimonial__quote{

    }

    .testimonial__author{
        font-size: 1.5rem;
        color: $brand-color-2;
        font-weight: bolder;
    }
}